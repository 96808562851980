import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

import Logo from "../../assets/images/logo.svg"
import MapImg from "../../assets/images/map.png"
import Shape1 from "../../assets/images/shape1.png"
import Shape2 from "../../assets/images/shape2.svg"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area bg-f7fafd">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-sm-8">
            <div className="single-footer-widget">
              <div className="logo">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="logo"
                    style={{
                      maxWidth: "4rem",
                      marginLeft: "0.9rem",
                      marginTop: "-1rem",
                    }}
                  />
                </Link>
              </div>
              <p className="FooWidth" style={{ marginLeft: 15 }}>
                We are working on a problem, never think about beauty.<br></br>
                We only think how to solve the problem.<br></br>
                But when we have finished, if the solution is not beautiful,<br></br>
                We know it is wrong.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-4 d-flex justify-content-center align-items-center">
            <Link to="/contact" className="btn btn-primary">
              Contact Us
            </Link>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="copyright-area d-flex justify-content-between align-items-center">
              <p className="mb-0">
                Copyright &copy; {currentYear} All rights reserved by Sans Web Solutions
              </p>
              <Link to="/privacy-policy" style={{ color: "#6084a4" }}>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>

      <img src={MapImg} className="map" alt="map" />

      {/* Shape Images */}
      <div className="shape1">
        <img src={Shape1} alt="shape" />
      </div>
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
    </footer>
  )
}

export default Footer