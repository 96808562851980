import React from "react"
import Layout from "../components/_App/layout"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"

const PrivacyContent = ({ title, children }) => (
  <div className="single-services-box-item p-8 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
    <h2 className="text-3xl font-bold mb-6 text-gray-900 border-b pb-4">
      {title}
    </h2>
    <div className="text-base text-gray-700 space-y-4 leading-relaxed">
      {children}
    </div>
  </div>
)

const ListItem = ({ children }) => (
  <li className="flex items-start gap-3 mb-3">
    <span className="flex-shrink-0 mt-2">
      <div className="w-1.5 h-1.5 rounded-full bg-blue-600"></div>
    </span>
    <span className="text-base">{children}</span>
  </li>
)

const PrivacyPolicy = () => (
  <Layout>
    <Navbar />
    <div
      className="bigdata-services-area"
      style={{ backgroundColor: "#f8fafc" }}
    >
      <div
        className="container mx-auto px-4 max-w-6xl"
        style={{ paddingBottom: "100px" }}
      >
        <div
          className="section-title text-center mt-10"
          style={{ marginTop: "85px" }}
        >
          <h1
            className="text-5xl font-bold text-gray-900 mb-4"
            style={{ paddingTop: "20px" }}
          >
            Privacy Policy
          </h1>
          <div className="bar w-24 h-1 bg-blue-600 mx-auto rounded-full mb-6"></div>
          <p className="text-lg text-gray-600">
            Last Updated: January 23, 2025
          </p>
        </div>

        <div className="space-y-8">
          <PrivacyContent title="1. Introduction">
            <p className="text-base mb-6">
              At our company, we are committed to protecting your privacy and
              ensuring the security of your personal information. This Privacy
              Policy explains how we collect, use, disclose, and safeguard your
              information when you use our services.
            </p>
            <p className="text-base">
              By using our services, you consent to the practices described in
              this Privacy Policy. We encourage you to read this document
              carefully to understand our practices regarding your personal
              data.
            </p>
          </PrivacyContent>

          <PrivacyContent title="2. Information We Collect">
            <div className="space-y-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-3">
                2.1 Personal Information
              </h3>
              <ul className="list-none space-y-2">
                <ListItem>
                  Contact information (name, email address, phone number, postal
                  address)
                </ListItem>
                <ListItem>
                  Account credentials (username, password, security questions)
                </ListItem>
                <ListItem>
                  Payment information (credit card details, billing address)
                </ListItem>
                <ListItem>
                  Professional information (company name, job title, industry)
                </ListItem>
              </ul>

              <h3 className="text-xl font-semibold text-gray-800 mb-3">
                2.2 Technical Information
              </h3>
              <ul className="list-none space-y-2">
                <ListItem>
                  Device information (IP address, browser type, operating
                  system)
                </ListItem>
                <ListItem>
                  Usage data (pages visited, time spent, interaction patterns)
                </ListItem>
                <ListItem>
                  Location data (when permitted by your device settings)
                </ListItem>
                <ListItem>Cookies and similar tracking technologies</ListItem>
              </ul>
            </div>
          </PrivacyContent>

          <PrivacyContent title="3. How We Use Your Information">
            <p className="text-base mb-4">
              We use your information for the following purposes:
            </p>
            <ul className="list-none space-y-2">
              <ListItem>Providing and improving our services</ListItem>
              <ListItem>
                Processing transactions and sending related communications
              </ListItem>
              <ListItem>
                Personalizing your experience and delivering targeted content
              </ListItem>
              <ListItem>
                Analyzing usage patterns to enhance our service quality
              </ListItem>
              <ListItem>
                Detecting and preventing fraudulent activities
              </ListItem>
              <ListItem>
                Complying with legal obligations and enforcing our terms
              </ListItem>
            </ul>
          </PrivacyContent>

          <PrivacyContent title="4. Data Protection & Security">
            <p className="text-base mb-6">
              We implement industry-standard security measures to protect your
              personal information:
            </p>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                  Technical Measures
                </h3>
                <ul className="list-none space-y-2">
                  <ListItem>SSL/TLS encryption for data transmission</ListItem>
                  <ListItem>
                    Secure data storage with encryption at rest
                  </ListItem>
                  <ListItem>
                    Regular security audits and penetration testing
                  </ListItem>
                  <ListItem>Multi-factor authentication options</ListItem>
                </ul>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                  Organizational Measures
                </h3>
                <ul className="list-none space-y-2">
                  <ListItem>Employee confidentiality agreements</ListItem>
                  <ListItem>Regular staff security training</ListItem>
                  <ListItem>Access control and monitoring</ListItem>
                  <ListItem>Incident response procedures</ListItem>
                </ul>
              </div>
            </div>
          </PrivacyContent>

          <PrivacyContent title="5. Your Rights">
            <p className="text-base mb-6">
              You have the following rights regarding your personal information:
            </p>
            <div className="grid md:grid-cols-2 gap-6">
              <ul className="list-none space-y-2">
                <ListItem>Right to access your personal data</ListItem>
                <ListItem>Right to rectification of inaccurate data</ListItem>
                <ListItem>Right to erasure ('right to be forgotten')</ListItem>
                <ListItem>Right to restrict processing</ListItem>
              </ul>
              <ul className="list-none space-y-2">
                <ListItem>Right to data portability</ListItem>
                <ListItem>Right to object to processing</ListItem>
                <ListItem>Right to withdraw consent</ListItem>
                <ListItem>Right to lodge a complaint</ListItem>
              </ul>
            </div>
          </PrivacyContent>

          <PrivacyContent title="6. Contact Information">
            <p className="text-base mb-6">
              If you have any questions about this Privacy Policy or our data
              practices, please contact our Data Protection Officer:
            </p>
            <div className="bg-gray-50 p-6 rounded-lg">
              <div className="space-y-4">
                <div className="flex items-center gap-3">
                  <span className="text-base text-gray-600">
                    nikhil@sanswebsolutions.com
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  <span className="text-base text-gray-600">
                    +91 9584860344
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  <span className="text-base text-gray-600">
                    AB Road, NEAR MALWA TOWER, Old Palasiya,
                    <br /> Indore, Madhya Pradesh, 452001
                  </span>
                </div>
              </div>
            </div>
          </PrivacyContent>
        </div>
      </div>
    </div>
    <Footer />
  </Layout>
)

export default PrivacyPolicy
