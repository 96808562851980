import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import MainBanner from "../components/ITStartup/MainBanner"
import Features from "../components/ITStartup/Features"
import ServicesArea from "../components/ITStartup/ServicesArea"
import OurFeatures from "../components/ITStartup/OurFeatures"
import Team from "../components/Common/Team"
import FunFactsArea from "../components/Common/FunFactsArea"
import RecentWorks from "../components/Common/RecentWorks"
import PricingStyleOne from "../components/PricingPlans/PricingStyleOne"
import Feedback from "../components/Common/Feedback"
import Partner from "../components/Common/Partner"
import BlogPost from "../components/Common/BlogPost"
import Footer from "../components/_App/Footer"
import Logging from "../components/_App/logging"

const IndexPage = () => (
  <Layout>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        height: 47,
        zIndex: 9999,
        position: "sticky",
        backgroundColor: "#0d98c6",
        color: "white",
        padding: 3,
      }}
    >
      Discuss your Project with Us, For a Free Consultation Call &nbsp;{" "}
      <a
        href="https://calendly.com/nikhil-sanswebsolutions/30min"
        className="blink_me"
        target="_blank"
        style={{ color: "yellow", cursor: "pointer" }}
      >
        {" "}
        Click Here
      </a>
    </div>
    <Logging title={"Home"} />
    <SEO title="Home" />
    <Navbar />
    <MainBanner />
    <Features />
    <ServicesArea />
    <OurFeatures />
    <Team />
    <FunFactsArea />
    <RecentWorks />
    <PricingStyleOne />
    {/* <Feedback /> */}
    <Partner />
    <BlogPost />
    <Footer />
  </Layout>
)

export default IndexPage
